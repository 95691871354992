
.Toastify__progress-bar--default { background-color: linear-gradient(
    to right,
    #4cd964,
    #4cd964,
    #4cd964,
    #4cd964,
    #4cd964,
    #4cd964
  );
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

html {
  font-family: 'Roboto', sans-serif !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
}
body {
  font-family: 'Roboto', sans-serif !important;

}
h1 { 
  text-align: center !important;
}

.loader {
  display: none;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.loading {
  border: 2px solid #ccc;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-top-color: #1ecd97;
  border-left-color: #1ecd97;
  animation: spin 1s infinite ease-in;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button-group2{
display: flex;
    justify-content: center;
}
.btn-yes {
  cursor: pointer;
  color: white !important;
  background-color: #0081A6;
  padding:15px;
  border-radius: 15px;
  
}
button.shake{
    animation: shake 1s infinite;
}
@keyframes shake {
  0%  { transform: translate(2px, 1px)   rotate(0deg);  }
  10% { transform: translate(-1px, -2px) rotate(-2deg); }
  20% { transform: translate(-3px, 0px)  rotate(3deg);  }
  30% { transform: translate(0px, 2px)   rotate(0deg);  }
  40% { transform: translate(1px, -1px)  rotate(1deg);  }
  50% { transform: translate(-1px, 2px)  rotate(-1deg); }
  60% { transform: translate(-3px, 1px)  rotate(0deg);  }
  70% { transform: translate(2px, 1px)   rotate(-2deg); }
  80% { transform: translate(-1px, -1px) rotate(4deg);  }
  90% { transform: translate(2px, 2px)   rotate(0deg);  }
  100%{ transform: translate(1px, -2px)  rotate(-1deg); }
}
.no {
  background-color: #0081A6;
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

}


/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80% !important; /* Could be more or less, depending on screen size */
}
.foo-bar {
  background-color: #0081A6;
  border-radius: 5px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


.checkout-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
.cart {
transition: 1s linear;
  border-bottom-width: 3px;
  border-top-width: 3px;
}
.total-price {
  font-weight: bold;
  margin: 0px;
}

.flex-provider {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.space {
  height: 20px;
}
@media (min-width: 600px) {
.provider-grid {
  max-width: 1200px;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
}
.time {
  padding: 10px;
  background-color: #0081A6;
  margin: 10px;
  border-radius: 10px;
  color: white !important;
  cursor: pointer;
  transition: linear 140ms;
}
.time:hover {
  background-color: grey;
}
.react-calendar {
  width: 500px;
  max-width: 100%;
  background: white;
  border: 0px;
}
.react-calendar__tile--active {
  background: #0081A6;
  border-radius: 15px;
}
.calendar-flex {
  display: flex;
  justify-content: center;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #0081A6;
  color: white;
  transition: linear 140ms;
  border-radius: 15px;
}
.react-calendar__navigation button {
  color: #0081A6;
  font-weight: bold;
}
.react-calendar__tile {
  border-radius: 15px;

}
.react-calendar__tile--now {
  background: white;
  color: #0081A6;
  border-radius: 15px;

}
.react-calendar__tile:enabled:hover{
  background: grey;
  border-radius: 15px;
  color: white;
  transition: linear 140ms;

}
.time-title {
  margin-top: 35px;
  text-align: center;
}
.time-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.card-provider {
  all: unset;
}
.card-provider:hover {
  all: unset;
}
.provider-image {
  margin-bottom: 10px;
  height: 100px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;

  object-fit: cover;
}
.provider-link {
  padding: 0px;
  box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 6%);
  border-radius: 10px;
  margin: 10px; 
  cursor: pointer;
}
.provider-link:hover {
  box-shadow: 2px 2px 11px 2px rgb(0 0 0 / 6%);

}
.grid {
    margin-top: 5px;
    display: grid;
    align-items: center;
}
.cart-item {
 color: black;
 margin: 0px;

}
.responsive {
  display: flex;
  gap: 20px;
}
.card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 15px;
}
.r2c-col1 {
  flex: 1;
  width: 100%;
 
}
.trasn-icon {
  color: darkgrey;
}
.trasn-icon:hover {
  color: grey
}


.accordion {
  cursor: pointer;
  border-width: 0px 0px 0px 0px;
  display: flex;
  padding: 15px;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 6%);
  border-radius: 10px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  transition: border-width 0.2s linear;
}

.accordion[aria-expanded="true"] {
  border-style: solid;
  border-width: 0px 0px 0px 8px;
  border-color: #0081A6;
  transition: border-width 0.2s linear;
  
}
.accordion[aria-expanded="false"] {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #0081A6;
  transition: border-width 0.2s linear;
  
}
.accordion .toggle {
  width: 16px;
  height: 16px;
  align-self: center;
  margin-left: auto;
  transition: all 0.35s ease;
}

.accordion .toggle[aria-expanded="true"] {
  transform: rotateZ(90deg);
}

.accordion-content {
  
  overflow: hidden;
  max-height: 100%;
  transition: max-height 1s ease-in-out;
}

.accordion-content[aria-expanded="true"] {
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.r2c-col-format {
top: 10px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 6%);
}
.r2c-col2 {
  min-width: 250px;
  flex: 1;
  width: 100%;
  position: sticky;
  
  height: 100%;
}
.add-to-cart {
  cursor: pointer;
  background-color: #0081A6;
  padding: 10px;
  border-radius: 10px;
  color: white;
  transition: 0.3s;
}
.add-to-cart:hover {
  background-color: #808080;
}
.add-to-cart i {
  color: white;
}
.cart-button {
  cursor: pointer;
  background-color: #0081A6;
  padding: 8px;
  border-radius: 7px;
  color: white !important;
  transition: 0.3s;
}

.confirm {
  display: block;
  cursor: pointer;
  background-color: #0081A6;
  padding: 8px;
  border-radius: 7px;
  color: white !important;
  transition: 0.3s;
  width: 100%;
  text-align: center;
}
.confirm:hover {
  background-color: #808080;
}
.cart-button:hover {
  background-color: #808080;
}
.cart-title {
 
  margin: 0px 0px 10px 0px;
}
.price {
  background-color: #0081A6;
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  margin-right: 20px;
  text-align: center;
}
.table {
  width: 100%;
}
.link {
  cursor: pointer;
  text-align: right;
}
.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.table td, .table th {
  padding: 0.75rem 0rem 0.75rem 0rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.Collapsible__trigger {
background-color: #0081A6;
width: 100%;
}

.img {
  width: 100%;
  max-height: 100px;
  object-fit: cover;
  border-radius: 0px 10px 0px 0px;

}

.card {
  border-left-width: 8px;
  border-color: #0081A6;
  display: block;
  padding: 0px;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 6%);
  border-radius: 10px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  
}
.subtitle {
  color: gray;
  font-size: 12px;
}
.description {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
   color: #8b8b8b;
   margin-top: 5px;
   margin-bottom: 0px;
}
.card-body {
  padding: 15px;
}
.title {
  color: #4d4d4d;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.description {
 
}
h2 {
  color: #4d4d4d !important;
  font-size: 20px !important;
  margin-bottom: 0px !important;
  font-weight: bold !important;
  
}
p {
  text-align: left !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important; /* number of lines to show */
          line-clamp: 4 !important; 
  -webkit-box-orient: vertical !important;
  margin-bottom: 35px;
}
.card-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

/* RESOURCE #17 END */
/* RESOURCE #22 BEGIN */
.steps
{
    display: flex;
    justify-content: space-evenly;
    align-content: stretch;
    margin: 2em 0 2em 0;
    padding: 0;
    box-shadow: 0px 0.625em 1.875em #00000017;
    border-radius: 0.375em;
    overflow: hidden;
}
.steps>*
{
    margin: 0;
    width: 100%;
    padding: 0.9em 1em 0.9em 2.1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    border-right: 1px solid #cccccc;
    color: #333;
    background: #fafafa;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease;
}
.steps>*[data-active="active"],
.steps>*[data-active="y"]
{
    color: #fff;
    background: #0081A6;
    border-right: 1px solid #0081A6;
}
.steps>*:before
{
    content: attr(data-step-name);
    font-size: 0.8em;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    transition: all 0.2s ease;
}
.steps>*:after
{
    content: '';
    display: block;
    background: #fafafa;
    position: absolute;
    width: 2.2em;
    height: 2.2em;
    transform: translateY(-50%) rotate(45deg);
    top: 50%;
    right: -1.2em;
    z-index: 1;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}
.steps>*[data-pageid="{system.page.id}"]:after,
.steps>*[data-active="active"]:after,
.steps>*[data-active="y"]:after
{
    background: #0081A6;
    border-top: 1px solid #0081A6;
    border-right: 1px solid #0081A6;
}
.steps>*:last-child
{
    border: none;
}
.steps>*:last-child:after
{
    display: none;
}

.steps>*:hover,
.steps>*.inlinelink:hover
{
    color: #333;
    text-decoration: none;
}

.steps>*[data-pageid="{system.page.id}"]:hover,
.steps>*[data-active="active"]:hover,
.steps>*[data-active="y"]:hover
{
    color: #fff;
    text-decoration: none;
}



.steps>*[href="#"],
.steps>*[href=""]
{
    color: #999;
    pointer-events: none;
}

@media screen and (max-width: 600px)
{
  .responsive {
    flex-wrap: wrap;
    flex-direction: column-reverse;
}
.r2c-col2 {
    position: inherit;
  
}
    .steps
    {
        flex-direction: column;
    }
    .steps>*
    {
        padding: 1.4em 1em 1em 1em;
        margin: 0;
        border: none;
        border-bottom: 1px solid #cccccc;
    }
    .steps>*:first-child
    {
        padding: 1em;
    }
    .steps>*:hover
    {
        text-decoration: hover;
        border-bottom: 1px solid #cccccc;
    }
    .steps>*:hover:last-child
    {
        border-bottom: none;
    }
    .steps>*:after
    {
        top: unset;
        right: unset;
        width: 1.5em;
        height: 1.5em;
        transform: translateY(0) rotate(0);
        bottom: -11.84px;;
        left: 50%;
        transform: translateX(-50%) rotate(135deg);
    }
}

.disabled {
  background-color: gray !important;
}
.steps a[data-active="y"]
{
color: white !important;
font-weight: bold !important;
}
.steps a[data-active="d"]
{
cursor: pointer !important;
}

.imgPreview {
 display: block;
  max-width:230px;
  
  max-height:95px;
  min-height:95px;

  width: auto;
  height: auto;
}
.file-inner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
.file-flex {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}
.file-button-before-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;

}
.file-button-after-upload {
  border: 1px solid #ccc;
  background-color: #0081A6;
  color: white;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.table-personal {
  border-collapse: collapse;
  width: 100%;
}

.table-personal td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-personal tr:nth-child(even){background-color: #f2f2f2;}

.table-personal tr:hover {background-color: #ddd;}

.table-personal th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0081A6;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0081A6;
}

input:focus + .slider {
  box-shadow: 0 0 1px ;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switchLabel {
  display: flex;


}

.rowInput {
  display: flex;
    gap: 10px;
}

.thxtitle {
  text-align: center;
  margin: 10px
}


.thxicon {
  color:#0081A6;
  font-size: 50px !important;

  padding: 20px;
  border: #0081A6;
  border-style: solid;
  border-radius: 50%;
}
.thxblock {
  text-align: center;
  margin-top: 90px
}
.thxtext {
  text-align: center !important;
}
.header {
  background-color:#0081A6;
  height: 70px;
  width: 100%;
  position: relative;
}

.body-container {
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-right: 15px;
  padding-left: 15px;
}
.payments-container {

  background-color: #0081A6;
  height: 70px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.payments {
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-right: 15px;
  padding-left: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payments-text {
  max-width: 1200px !important;
  color: white ;
  margin-bottom: 0px
}

.container {
  padding-right: unset !important;
  padding-left: unset  !important;
  margin-right: unset  !important;
  margin-left: unset  !important;
  max-width: unset  !important;
}
.header-img {

  width: 100px
}
.header-body {
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-right: 15px;
  padding-left: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item {

  align-items: center;
}
.text-right {
  text-align: right;
  color: white !important;

}
.stepsSelector {
  display: flex;
  gap: 10px;
}

.buttom-navi {
  height: 200px;
  width: 100%;
  background-color: #0081A6;
  position: absolute;
  bottom: 0;

}